/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 100%;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  position: relative;
  border-right: 1px solid rgba(112, 112, 112, 0.15);
  z-index: 1000;
  left: -250px;
  padding-top: 10px;
  width: 0;
  height: 100%;
  background: #ffffff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

.main #page-content-wrapper {
  padding: 0px 20px 20px;
  position: absolute;
  height: unset;
  bottom: 0;
  top: 75px;
  left: 250px;
  right: 0;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar {
  position: absolute;
  top: 0;
  bottom: 10px;
}

.sidebar-nav {
  display: inline-block;
  width: 250px;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  list-style: none;
}

.sidebar-nav li {
  line-height: 30px;
  padding-left: 20px;
  color: #333;
}

.sidebar-nav li a {
  text-indent: 0;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: #333;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  background: none;
  color: #345784;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
  color: #345784;
}

.sidebar-nav li a.disabled {
  color: #999999;
}

.sidebar-nav li a.disabled:hover {
  opacity: 1;
  background: none;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li.selected a {
  font-weight: bold;
  color: #345784;
}

.sidebar-nav .divider {
  min-height: 5px;
  font-weight: bold;
  margin-top: 10px;
  position: relative;
}

.sidebar-nav .divider div {
  display: inline-block;
}

.sidebar-nav li.selected a,
.sidebar-nav li.selected + li a {
  border: none;
}

.sidebar-nav > .sidebar-brand {
  padding-left: 0;
  text-align: center;
  text-indent: 0;
}

.sidebar-nav > .sidebar-brand:hover {
  background: none;
}

.sidebar-nav > .sidebar-brand.main,
.sidebar-nav > .sidebar-brand.main:hover {
  margin-top: 10px;
}

.sidebar-nav > .sidebar-brand.main + li:not(.sidebar-brand) {
  margin-top: 10px;
}

.multi-tenant .sidebar-nav .sidebar-brand.main {
  margin-top: 0px;
}

.sidebar-nav li .preview-button {
  border: 1px solid #00000080;
  display: inline-block;
  padding: 0px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-sub-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-sub-nav li {
  text-indent: 40px;
}

.sidebar-toggle-btn,
.sidebar-toggle-btn:focus {
  position: absolute;
  top: 15px;
  color: #345784;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#sidebar-wrapper .sidebar-toggle-btn {
  left: 0px;
  top: 15px;
}

.multi-tenant .sidebar-toggle-btn {
  top: 40px;
}

.multi-tenant .sidebar-toggle-btn.main-page {
  top: 40px;
}

.multi-tenant #sidebar-wrapper .sidebar-toggle-btn {
  top: 40px;
}

.sidebar-toggle-btn:hover {
  color: #333;
}

.sidebar-toggle-btn:active,
.sidebar-toggle-btn.active {
  color: #000;
  box-shadow: none;
}

.sidebar-toggle-btn:active:focus,
.sidebar-toggle-btn:focus {
  outline: none;
}

.sidebar-toggle-btn.main-page {
  left: 0;
  top: 15px;
  right: auto;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

#wrapper.toggled .sidebar-toggle-btn.main-page {
  visibility: visible;
  opacity: 1;
}

.sidebar-toggle-btn.main-page:hover {
  color: #345784;
}

/*@media(min-width:768px) {*/
#wrapper {
  padding-left: 250px;
}

#wrapper.toggled {
  padding-left: 0;
}

#sidebar-wrapper {
  width: 250px;
}

#wrapper.toggled #sidebar-wrapper {
  width: 0;
}

#page-content-wrapper {
  padding: 0px 20px 20px;
  height: 100%;
  position: relative;
}

#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: 0;
}
/* }*/
