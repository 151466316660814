@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Italic.ttf');
  font-style: italic;
}

html {
  height: 100%;
}

body {
  background-color: #ffffff;
  color: #000000;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  padding: 0;
  height: 100%;
}

.main {
  height: 100%;
}

h1.page-title {
  font-weight: bold;
  font-size: 24px;
  margin-top: 25px;
  margin-bottom: 0px;
}

.subtitle {
  display: none;
}

.modal-header.module-header {
  display: block;
  border-radius: 5px 5px 0px 0px;
  background: #ffffff;
  color: #000000;
  border-bottom: 1px solid #dddddd;
  padding: 5px 15px;
  text-transform: none;
}

h4.modal-title,
span.modal-title {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 9px 0px;
}
.btn,
.btn.btn-primary {
  padding: 7px 16px;
}

.icn-primary {
  color: #345784;
  background-color: #fff;
}

.icn-primary:hover:active:focus {
  filter: brightness(85%);
  outline: none;
  border: none;
}

.btn,
.btn.btn-primary,
.btn:hover,
.btn.btn-primary:hover,
.btn.btn-success:hover {
  background-color: #345784;
  border: 0;
  color: #fff;
  line-height: 19px;
}

.btn:hover,
.btn.btn-primary:hover,
.btn:active,
.btn.btn-primary:active,
.btn:focus,
.btn.btn-primary:focus {
  color: #fff;
}

.btn.btn-success:hover,
.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:focus-visible,
.btn.btn-success.disabled,
.btn.btn-success[disabled] {
  background-color: #345784;
  border-color: #345784;
}

.btn.green {
  background: #155724 !important;
}

.btn.red {
  background: #a94442 !important;
}

.opaque {
  opacity: 0.5;
}

.control-label {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.module-body form {
  background-color: unset;
}

.form-control {
  font-weight: normal;
  border: 1px solid #00000080 !important;
  border-radius: 5px;
  box-shadow: none !important;
  background-color: transparent !important;
}
.form-control:disabled {
  background-color: #dddddd !important;
}

.form-border-top {
  border-top: 1px solid #00000080;
}

.form-control-wrapper {
  display: flex;
  align-items: center;
}

.form-control-help-icon {
  margin-left: 10px;
}

.form-control-margin > * {
  margin-bottom: 1.5rem;
}

.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.row {
  margin-bottom: 10px;
}

.table-header {
  cursor: pointer;
}

.table-header.left,
.table-cell.left {
  text-align: left;
}

.table-header.center,
.table-cell.center {
  text-align: center;
}

.table-header.right,
.table-cell.right {
  text-align: right;
}

.table-header.no-pointer {
  cursor: default;
}

.table-header-disabled {
  cursor: default;
}

.st-sort-ascent:before {
  content: '\25B2';
}

.st-sort-descent:before {
  content: '\25BC';
}

.table > tbody > tr > td {
  vertical-align: middle;
  border: 0;
  line-height: 19px;
  padding-left: 0;
}

.table > tbody > tr > td:first-child {
  padding-left: 8px;
}

.table > tbody > tr:last-child {
  border-bottom: 1px solid #00000080;
}

.table > tbody > tr:first-child {
  border-top: 1px solid #00000080;
}

.table > thead > tr > th {
  border: 0;
  padding-left: 0;
  padding-top: 20px;
}

.pagination > li > a,
.pagination > li > span,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border: 1px solid #00000080;
}

.small-footer {
  padding: 0 !important;
}

.small-footer nav ul {
  margin: 10px !important;
}

.ok {
  color: green;
}

.warning {
  color: #ffd90b;
}

.btn.padded {
  margin-right: 5px;
  margin-left: 5px;
}

.btn.top-left {
  vertical-align: top;
  float: left;
}

.modal-footer,
.modal-footer .text-center {
  text-align: left;
}
.modal-footer .left {
  float: left;
}

.module .modal-footer .btn.btn-primary,
.action-buttons .btn.btn-primary {
  float: left;
  margin-right: 10px;
}

.module .modal-footer .btn.btn-default,
.module .modal-footer .btn.btn-default:hover,
.action-buttons .btn.btn-default,
.btn.btn-cancel {
  background: transparent;
  color: #000 !important;
}

.btn.btn-default:focus,
.btn.btn-default:focus-visible,
.btn.btn-cancel:focus,
.btn.btn-cancel:focus-visible,
.btn.btn-default:active,
.btn.btn-cancel:active {
  outline: none;
  box-shadow: none;
}

.modal-warning {
  margin-top: 1em;
  color: darkred;
}

.panel.login {
  margin-top: 20px;
  border: 0;
  box-shadow: none;
}

.panel.login .panel-heading {
  display: none;
}

.panel.login form {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.panel.login .help-block {
  color: red;
}

.form-group.auto-height {
  overflow: auto;
}

.form-field-indent {
  margin-left: 25px;
}

ng-form.ng-invalid {
  border: none !important;
}

form input.ng-invalid.ng-dirty {
  border: 1px solid red;
}

form input.ng-valid {
  border: 1px solid green;
}

.field-instruction {
  color: #9a9a9a;
  font-size: 0.95em;
}

form span.error {
  display: inline-block;
  font-size: medium;
  color: red;
  padding-bottom: 5px;
}

.asset-preview {
  float: left;
  margin-left: 10px;
}

.asset-preview .image-container {
  background: #bbbbbb;
  display: inline-block;
}

.asset-preview img.small-preview {
  max-height: 200px;
  max-width: 300px;
}

textarea.file-editor {
  height: 60vmin;
}

textarea.message-editor {
  height: 16vmin;
}

.action-buttons button {
  margin-left: 2px;
}
.action-buttons.flex {
  display: flex;
  justify-content: flex-end;
}
.action-buttons.flex-space {
  display: flex;
  justify-content: space-between;
}
.action-buttons a {
  cursor: pointer;
}
.action-buttons .btn i {
  padding-right: 5px;
}

/* Styles for react action buttons */
.action-buttons {
  position: relative;
}

.action-buttons .action-ellipsis {
  font-size: 20px;
  line-height: 22px;
  padding: 0px 10px;
  cursor: pointer;
}

.action-buttons .hidden {
  display: none;
}

.action-buttons .action-menu {
  position: absolute;
  z-index: 10;
  right: 5px;
  top: 10px;
  display: block;
  background-color: #fff;
  border: 1px solid #00000080;
  border-radius: 5px;
  padding: 5px;
}

.action-buttons .action-menu .btn {
  display: block;
  clear: both;
  float: unset !important;
  background-color: #fff !important;
  color: #000 !important;
  margin: 0px;
  padding: 7px 5px;
}

.action-buttons .action-menu .btn,
.action-buttons .action-menu .btn.focus,
.action-buttons .action-menu .btn.active,
.action-buttons .action-menu .btn:hover,
.action-buttons .action-menu .btn:active,
.action-buttons .action-menu .btn:focus,
.action-buttons .action-menu .btn:focus-visible,
.action-buttons .action-menu .btn i:focus-visible,
.action-buttons .action-menu .btn:focus-within {
  border: 0;
  box-shadow: none;
  outline: none;
}

.action-buttons .action-menu .btn i {
  padding-right: 10px;
}

.selector-wrapper {
  position: absolute;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  font-size: 30px;
  line-height: 35px;

  display: flex;
  justify-content: flex-start;
}

.selector .filter-label {
  font-weight: bold;
  display: inline-block;
  padding-right: 5px;
}

.page-controls {
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  align-items: stretch;
  flex: 1;
}

.page-controls.title-bottom {
  top: 35px;
}

.selector-cell {
  width: 50px;
  text-align: center;
}
th.selector-cell input {
  margin-left: 8px;
}

.reorder,
.reorder-icon {
  cursor: grab;
}

.sortable-chosen,
.sortable-chosen .reorder,
.sortable-chosen .reorder-icon {
  cursor: grabbing;
}

.reorder-icon {
  color: #cccccc;
  font-size: 20px;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #ffffff;
}

.update-toggle-wrapper {
  float: left;
  width: 140px;
  top: 0;
  bottom: 0;
  position: relative;
  margin-right: 25px;
}

.toggle-container {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.page-filter {
  float: right;
  width: 300px;
}

.page-filter.sm {
  width: 150px;
}

.page-filter.med {
  width: 175px;
}

.page-filter.lg {
  width: 200px;
}

.page-filter .filter-label {
}

.page-filter .dropdown-toggle {
  float: right;
}

.details {
  font-weight: normal;
  font-size: 12px;
}

.asset-table-filename {
  float: left;
}

.asset-table-flag {
  float: left;
  color: green;
  margin-left: 10px;
}

@-moz-keyframes spinner-loader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-loader {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* :not(:required) hides this rule from IE9 and below */
.spinner-loader:not(:required) {
  -moz-animation: spinner-loader 1500ms infinite linear;
  -webkit-animation: spinner-loader 1500ms infinite linear;
  animation: spinner-loader 1500ms infinite linear;
  -moz-border-radius: 0.5em;
  -webkit-border-radius: 0.5em;
  border-radius: 0.5em;
  -moz-box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0,
    rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0,
    rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0,
    rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
  -webkit-box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0,
    rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0,
    rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0,
    rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0,
    rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0,
    rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0,
    rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
  display: inline-block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin: 1.5em;
  overflow: hidden;
  text-indent: 100%;
}

.reset-minus {
  color: #b20000;
  vertical-align: middle;
}

.reset-minus:hover {
  opacity: 0.5;
}

.block-borders div {
  border: 2px dashed green;
}

.clickable {
  cursor: pointer;
}

.modal-content {
  border-radius: 5px;
}

.module {
  background-color: #fff;
}

.modal-backdrop {
  z-index: 10500 !important;
  background-color: unset;
}

.modal {
  z-index: 10600 !important;
}

.module-header {
  color: #000;
  background-color: transparent !important;
  padding: 5px 0px;
  font-size: 18px;
}

.modal-header .btn.btn-default {
  color: #000 !important;
}

.modal-content .module {
  border: none;
  border-radius: 5px;
}

.module .modal-header button.close {
  background: #fff;
  color: #000;
  opacity: 1;
  vertical-align: middle;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 18px;
}

.modal-header .close {
  margin-top: 0px;
}
.module-body {
  padding: 10px 0 0;
}

.col-xs-12 .module-body .row {
  margin-left: 0px;
}
.module .col-xs-12 {
  padding-left: 0;
}

.module .modal-body .col-xs-12 {
  padding-left: 15px;
}

.module-body .content {
  padding: 10px 0;
}

.module-footer {
  border-top: 1px solid #33333340;
  margin-left: -35px;
  margin-right: -35px;
  padding-top: 10px;
}

.module-sub-header {
  background-color: #345784;
  color: #fff;
  padding: 1px 5px 3px;
}

.module.fullscreen {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 15px;
  top: 85px;
}

.module.fullscreen .module-body {
  position: absolute;
  top: 40px;
  bottom: 55px;
  left: 0px;
  right: 15px;
}

.module a {
  cursor: pointer;
}

.cms-banner {
  box-shadow: 0px -2px 14px #00000029;
  position: relative;
  z-index: 9999;
}

.cms-banner-img {
  display: inline-block;
  background: transparent url('../img/combobulate.png') no-repeat;
  background-size: contain;
  background-position: left 3px;
  width: 250px;
  height: 75px;
}

.cms-banner-img::after {
  content: '';
  position: absolute;
  background-color: #878787;
  width: 2px;
  height: 60%;
  top: 20%;
  left: 249px;
  border-radius: 25px;
}

.cms-banner-title {
  display: inline-block;
  color: #345784;
  background-color: #34578408;
  padding: 2px 10px 2px 17px;
  border-radius: 3px;
  position: relative;
  top: -30px;
  margin-right: 5px;
}

.cms-banner-user {
  display: inline-block;
  position: relative;
  float: right;
  color: #5e6e82;
  font-size: 30px;
  top: 25px;
  right: 20px;
}

.float-right {
  position: relative;
  float: right;
  clear: none;
}
.border-top {
  border-top: solid 1px #c6ced0;
}

.border-bottom {
  border-bottom: solid 1px #c6ced0;
}

.border-left {
  border-left: solid 1px #c6ced0;
}

.border-right {
  border-right: solid 1px #c6ced0;
}

.form-inline .form-control.xs {
  width: 100px;
}

.form-inline .form-control.md {
  width: 40%;
}

.form-control.inline-block {
  width: 85%;
}

.inline-block {
  display: inline-block;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0px !important;
}

.padding-right {
  padding-right: 10px !important;
}

.padding-bottom {
  padding-bottom: 10px !important;
}

.padding-left {
  padding-left: 10px !important;
}

.padding-top {
  padding-top: 10px !important;
}

.margin-right {
  margin-right: 10px !important;
}

.no-bottom-margin {
  margin-bottom: 0px !important;
}

.margin-bottom {
  margin-bottom: 10px !important;
}

.margin-left {
  margin-left: 10px !important;
}

.margin-top {
  margin-top: 10px !important;
}

.bold {
  font-weight: 700;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.form-horizontal .control-label.text-left {
  text-align: left;
}

.form-group.required:after {
  content: '*';
  color: red;
}

.asterick {
  position: absolute;
  color: red;
  top: 0px;
  right: 5px;
}

label .asterick {
  position: relative;
  left: 2px;
}

.asterick-padding {
  padding-right: 30px;
}

.bordered {
  border: 1px solid #c6ced0;
}

.full-width {
  width: 100%;
}

.max-height-100-img {
  max-height: 100px;
  max-width: 100%;
}

.font-10px {
  font-size: 10px;
}

.font-12px {
  font-size: 12px;
}

.equal-height-cols.row {
  overflow: hidden;
}

.equal-height-cols [class*='col-'] {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

.form-group .help-block,
.help-block {
  display: none;
}

.form-group.has-error .help-block,
.form-group .has-error .help-block,
.help-block.always-on,
.has-error .help-block {
  display: block;
}

.form-group.input-short input,
.form-group.input-short select,
.form-group.input-short.creatable {
  width: 16em;
}

.form-group.input-normal input,
.form-group.input-normal select,
.form-group.input-normal.creatable {
  width: 32em;
}

.form-group.input-long input,
.form-group.input-long select,
.form-group.input-long.creatable {
  width: 100%;
}

.form-group.input-short input[type='checkbox'],
.form-group.input-normal input[type='checkbox'],
.form-group.input-long input[type='checkbox'] {
  width: unset;
}

.form-group label .required-annotation {
  margin-left: 0.5em;
}

.form-group .value-description {
  font-style: italic;
}

.form-header {
  font-size: 18px;
}

.help-block.small {
  font-size: 12px;
}

.growl-item.ng-enter,
.growl-item.ng-leave {
  -webkit-transition: 0.5s linear all;
  -moz-transition: 0.5s linear all;
  -o-transition: 0.5s linear all;
  transition: 0.5s linear all;
}

.growl-item.ng-enter,
.growl-item.ng-leave.ng-leave-active {
  opacity: 0;
}

.growl-item.ng-leave,
.growl-item.ng-enter.ng-enter-active {
  opacity: 1;
}

.directory-info-list {
  padding: 0 0 0 0px;
}

.directory-info-list .select-checkbox-container {
  display: flex;
  align-content: center;
  margin-left: -8px;
}

.category-select-all {
  padding-right: 5px;
}

.directory-info-list .select-checkbox-container > * {
  align-self: center;
}

.directory-info {
  font-size: 14px;
  line-height: 1.2;
  list-style-type: none;
  padding-left: 15px;
  background-image: url('../img/gray-menu.png');
  background-position-y: 50%;
  background-repeat: no-repeat;
}

.directory-info.flex {
  display: flex;
}

.directory-info.flex .include {
  display: flex;
  flex-basis: 100%;
}

.directory-info.flex .col-xs-1 {
  flex-basis: 8.33333333%;
}

.directory-info.flex .col-xs-2 {
  flex-basis: 16.66666667%;
}

.directory-info.flex .col-xs-3 {
  flex-basis: 25%;
}

.directory-info.flex .col-xs-4 {
  flex-basis: 33.33333333%;
}

.directory-info.flex .col-xs-5 {
  flex-basis: 41.66666667%;
}

.directory-info.flex .col-xs-6 {
  flex-basis: 50%;
}

.directory-info.flex .col-xs-7 {
  flex-basis: 58.33333333%;
}

.directory-info.flex .col-xs-8 {
  flex-basis: 66.66666667%;
}

.directory-info.flex .col-xs-9 {
  flex-basis: 75%;
}

.directory-info.flex .col-xs-10 {
  flex-basis: 83.33333333%;
}

.directory-info.flex .col-xs-11 {
  flex-basis: 91.66666667%;
}

.directory-info.flex .col-xs-12 {
  flex-basis: 100%;
}

.directory-info:not(.padding-bottom) {
  background-position-y: calc(50% + 5px);
}

.header-input {
  display: inline-block;
  width: 100%;
  max-width: 500px;
  float: left;
}

.header-input input.form-control {
  width: 100%;
}

.inline-padding {
  padding-top: 5px;
}

.dndPlaceholder {
  margin-right: -15px;
  margin-left: -15px;
  list-style: none;
  background: lightgray;
  height: 80px;
}

.directory-category-list .directory-info-list .dndPlaceholder {
  height: 80px;
}

.directory-category-list .dndPlaceholder {
  height: 25px;
}

.dndDragging:not(.dndDraggingSource) {
  background-color: #fff;
  opacity: 0.4;
  border-bottom: solid 1px #c6ced0;
}

.dndDraggingSource {
  display: none;
}

.directory-info-list .dragger {
  position: relative;
  float: left;
  margin-top: 40px;
  color: #c6ced0;
  margin-left: -15px;
}

.directory-info.held {
  background-image: url('../img/black-menu.png');
}

.dragDisabled.directory-info {
  background: none;
}

.module-sub-header .icon-img {
  float: right;
  color: #fff;
  padding-top: 4px;
  padding-right: 4px;
}

.directory-category-list {
  list-style: none;
  padding: 0;
}

.directory-category-list .ordering-mode,
.directory-category-list .ordering-mode {
  margin-bottom: 8px;
  margin-top: 8px;
}

.directory-category-list .ordering-mode .row,
.directory-category-list .ordering-mode .row {
  margin-bottom: 0px;
}

.remove-img {
  background: #b8312f;
  color: #ffffff;
  top: 50%;
  left: 36%;
  margin: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-decoration: none;
  z-index: 3;
  position: absolute;
  text-align: center;
  cursor: pointer;
  display: none;
  -webkit-transform: translateY(-50%) translateX(25%);
  -moz-transform: translateY(-50%) translateX(25%);
  -ms-transform: translateY(-50%) translateX(25%);
  -o-transform: translateY(-50%) translateX(25%);
  transform: translateY(-50%) translateX(25%);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.remove-img:hover {
  background-color: #bf4644;
}

.remove-img-container:hover > .remove-img {
  display: block;
}

.remove-img-container img {
  -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
}

.remove-img-container:hover img {
  opacity: 0.75;
}

.form-inline .checkbox {
  margin-right: 5px;
}

.angular-google-map-container {
  height: 400px;
}

.map-searchbox {
  margin-top: 9px;
  font-size: 12px;
  max-width: 50%;
}

.map-page .list-group {
  width: 100%;
  box-shadow: initial;
  border: none;
}

.map-page .list-buttons {
  min-width: 140px;
}

.list-group-item .fill-width {
  width: 100%;
}

.map-marker-btn {
  direction: ltr;
  overflow: hidden;
  text-align: center;
  position: relative;
  color: rgb(86, 86, 86);
  font-family: 'Open Sans', sans-serif;
  -webkit-user-select: none;
  font-size: 11px;
  padding: 8px;
  border-radius: 2px;
  -webkit-background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  border-left-width: 0px;
  min-width: 39px;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
}

.marker-list-group,
.region-list-group {
  line-height: 34px;
}

.marker-list-group .list-group-item,
.region-list-group .list-group-item {
  padding: 5px 15px;
}

.marker-list-group .marker-list-buttons,
.region-list-group .region-list-buttons {
  line-height: 30px;
}

.map-change-label.label {
  font-size: 13px;
}

.form-error-message {
  display: none;
}

.has-error .form-error-message {
  display: block;
  color: #a94442;
}

ul li {
  list-style-type: none;
}

.fr-element ul li {
  list-style-type: disc;
}

.fr-box.fr-basic .fr-element {
  padding: 16px !important;
}

ul.user-group-list {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  /*max-height: 70px;*/
  list-style: none;
}

ul.user-group-list > li {
  height: 20px;
}

.list-icon {
  font-size: 18px;
}

.manage-users-groups-icon.centered {
  padding-top: 7px;
}

.push-form {
  width: 500px;
}

.push-audience-option {
  position: relative;
}

.push-audience-option label {
  font-weight: normal;
  margin-left: 5px;
}

.advanced-toggle {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

.advanced-toggle.open {
  margin-bottom: 15px;
}

h4.modal-title:before,
span.modal-title:before {
  content: normal;
}

div.ams-button {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.group {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #666;
}

.group-conditions {
  margin-left: 20px;
}

.alert-group {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: black;
  background: #fff;
}

.condition,
.group {
  margin-top: 15px;
  margin-bottom: 15px;
}

.inline-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.with-search input {
  width: 95%;
  display: inline-block;
}

.with-search i {
  margin-left: 7px;
}

.multiselect-parent > .dropdown-menu > li a {
  cursor: pointer;
  user-select: none;
}

.multiselect-parent > .dropdown-menu > li a span .disabled {
  opacity: 0.5;
}

.multiselect-parent > .dropdown-menu > li a span .fromDefault {
  font-style: italic;
}

.radio-option label {
  margin-left: 5px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.inline-radio-option {
  display: inline-block;
  margin-left: 5px;
}

.inline-radio-option label {
  margin-left: 5px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.control-options {
  padding-top: 7px;
  margin-bottom: 0;
}

.flex-wrapper {
  display: flex;
}

.divider-icon {
  font-size: 18px;
  padding-right: 5px;
  margin-bottom: -2px;
}

.fa-users-line {
  font-size: 23px;
  height: 16px;
  overflow: hidden;
  padding-left: 20px;
}

.divider-icon.fa-users-line {
  font-size: 18px;
  height: 13px;
  padding-left: 0;
}

.glyphicon.statusglyph {
  word-wrap: break-word;
  font-weight: bold;
  color: slateblue;
  margin-left: 4px;
  vertical-align: middle;
}

.glyphicon.statusglyph.failed {
  word-wrap: break-word;
  font-weight: bold;
  color: red;
}

.form-group input[type='checkbox'] {
  margin-left: 5px;
}

.form-group input[type='textarea'] {
  width: 100%;
}

.module .btn.btn-default.ui-select-toggle,
.module .btn.btn-default.ui-select-toggle:hover,
.module .btn.btn-default.ui-select-toggle:focus {
  color: #000;
  background-color: #fff;
}

.ui-select-bootstrap .ui-select-choices-row > span {
  padding-left: 0;
  padding-right: 0;
}

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background-color: unset;
  color: unset;
}

.ui-select-bootstrap .ui-select-choices-row .selected {
  font-weight: bold;
  font-size: 1.1em;
}

li.ui-select-choices-group {
  padding-left: 10px;
  padding-right: 10px;
}

.form-group .instruction {
  color: #9a9a9a;
}

body tags-input .tags .tag-item {
  color: black;
}

tags-input.ng-invalid {
  border: none !important;
}

.dropdown-menu.preview li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu.preview li:hover,
.dropdown-menu.preview li:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.report-date-range {
  display: flex;
  margin-top: 10px;
  gap: 20px;
  width: 300px;
}

.modal-body .row {
  margin: 0;
}

.modal-body.module-body {
  padding: 20px 20px 20px 20px;
}

.welcome-message {
  position: absolute;
  color: transparent;
}

.pagination > li > a,
.pagination > li > span {
  color: #345784;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #dddddd;
  color: #000000;
  border: 1px solid #00000080;
}

.form-error-message {
}

.time-picker-label-container {
  height: 105px;
}

.time-picker-label {
  margin-top: 30px;
}

.time-picker-label-container.no-scroll-btns {
  height: 35px;
}

.no-scroll-btns .time-picker-label {
  margin-top: 10px;
}

.checkbox label {
  font-weight: bold;
}

.spinner-img {
  width: 35px;
  height: 35px;
}

.page-spinner {
  margin-left: 5px;
}

.page-spinner .spinner-img {
  width: 1em;
  height: 1em;
}

.value-detail {
  margin-left: 0.5em;
  color: #999999;
  font-style: italic;
}

.section-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link-accordion-toggle {
  margin-bottom: 1em;
}

.link-finder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link-button {
  background: transparent;
  color: #345784;
  cursor: pointer;
}

.link-button:focus,
.link-button:focus-visible,
.link-button:active {
  outline: none;
  box-shadow: none;
}

.time-picker-label-container {
  height: 105px;
}

.time-picker-label {
  margin-top: 30px;
}

.time-picker-label-container.no-scroll-btns {
  height: 35px;
}

.no-scroll-btns .time-picker-label {
  margin-top: 10px;
}

.table-message {
  font-style: italic;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 650px;
  }
}

.dz {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeee;
  background-color: #fafafa;
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  position: absolute;
  height: 20rem;
  width: 20rem;
}

.dz-img {
  max-width: 100%;
}

.dz-idle {
  border-color: #eeee;
}

.dz-focused {
  border-color: #345784 !important;
}

.dz-accepted {
  border-color: #155724 !important;
}

.dz-rejected {
  border-color: #a94442 !important;
}

/*********************

Release Manager
start
**********************/

.framework-version {
  float: right;
  margin-right: 1em;
}

/*********************

Release Manager
end
**********************/

/*********************

Target Audiences
start
**********************/

.tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip-container span.tooltip-text {
  display: none;
  position: absolute;
  z-index: 10;
  left: 5px;
  top: 10px;
  background-color: #fff;
  border: 1px solid #00000080;
  border-radius: 5px;
  padding: 5px;
  height: fit-content;
}

.tooltip-container:hover span.tooltip-text {
  display: block;
  opacity: 100;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50vw;
  text-overflow: ellipsis;
}

target-audience-editor-page .row.module-footer {
  margin-left: 0;
  margin-right: 0;
}

target-audience-editor-page .row.module-footer .action-buttons {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
}

/*********************

Target Audiences
end
**********************/

/*********************

Cohort
end
**********************/

.cohort-member-status {
  width: 110px;
}

.cohort-member-status i.enabled {
  opacity: 1;
}

.cohort-member-status i {
  opacity: 0.4;

  margin-left: 10px;
  margin-right: 10px;
}

.cohort-credential-code {
  width: 150px;
}

.cohort-members-legend i {
  width: 20px;
}

.cohort-members-legend .legend-item {
  padding-bottom: 5px;
  font-size: 0.9em;
}

/*********************

Cohort
end
**********************/

/*********************

User Management
start
**********************/

.flex-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-table-header-buttons {
  display: flex;
}

.flex-table-header-search {
  min-width: 25em;
}
